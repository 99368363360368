import React from 'react'
import { DataProcessingAgreement } from '../components/DataProcessingAgreement'
import Layout from '../components/Layout'

const DataPage = () => (
  <Layout justifyCenter title="Data processing agreement">
    <div className="page-container">
      <DataProcessingAgreement />
    </div>
  </Layout>
)

export default DataPage
