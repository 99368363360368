/* eslint-disable react/no-unescaped-entities */

import { Link } from 'gatsby'
import React from 'react'
import Block from '../components/Block'
import Page from '../components/Page'

export const DataProcessingAgreement = () => (
  <Page>
    <Block size="small">
      <h1>Data Processing Agreement</h1>

      <p>
        <i>Last updated 25 April 2024</i>
      </p>

      <p>
        This Data Processing Agreement (“<strong>DPA</strong>”) sets out the
        terms and conditions for the processing of Personal Data under and in
        connection with the{' '}
        <Link className="high-visibility" to="/terms">
          Agreement
        </Link>
        . This DPA forms an inseparable part of the Agreement.
      </p>
      <p>
        The Parties acknowledge that the provision of the Service involves
        Processing of Personal Data. To the extent Personal Data is processed in
        connection with the Service, the Parties acknowledge that the Customer
        is a Controller and Swarmia is a Processor processing Personal Data on
        behalf of the Customer.
      </p>
      <p>
        In the event of any discrepancy between this DPA and Terms of Service,
        this DPA prevails.
      </p>

      <ol className="nested-numbered-list">
        <li>
          <h2>Definitions</h2>
          <ol>
            <li>
              The terms used in this DPA, such as "Controller", "Processor",
              "Data Subject", "Special Categories of Personal Data",
              "Processing", "Data Protection Impact Assessment" and "Personal
              Data Breach", shall have the meanings as defined in the applicable
              Data Protection Regulation.
            </li>
            <li>
              “<strong>Personal Data</strong>” means any information relating to
              an identified or identifiable person, which Swarmia processes on
              behalf of the Customer or its Affiliates under the Agreement.
            </li>
            <li>
              "<strong>Data Protection Regulation</strong>" means all applicable
              laws relating to protection of Personal Data, including without
              limitation the CCPA, the GDPR and the national laws supplementing
              the GDPR and the laws implementing EU Directive 2002/58/EC.
            </li>
            <li>
              "<strong>CCPA</strong>" means the California Consumer Privacy Act,
              Cal. Civ. Code 1798.100 et seq., including any amendments and any
              implementing regulations thereto that become effective on or after
              the effective date of this DPA.
            </li>
            <li>
              “<strong>GDPR</strong>” means the EU General Data Protection
              Regulation (EU) 2016/679 and any amendments thereto.
            </li>
            <li>
              “<strong>Standard Contractual Clauses</strong>” means the Decision
              (EU) 2021/914 issued by the European Commission on 4 June 2021 on
              standard contractual clauses for the transfer of personal data to
              third countries, or any following decision of the Commission, and
              any amendments thereto.
            </li>
          </ol>
        </li>

        <li>
          <h2>Description of Processing</h2>
          <ol>
            <li>
              Swarmia processes Personal Data under the Agreement for the
              purpose of providing the Service to the Customer. Processing of
              Personal Data in this context refers to access to and analysis of
              data provided by the Customer in connection with the provision of
              the Service.
            </li>
            <li>
              Data Subjects are employees of the Customer or other individuals,
              whose Personal Data the Customer has provided to Swarmia in
              connection with the provision of the Service.
            </li>
            <li>
              For the purposes of the CCPA, the Parties acknowledge and agree
              that Swarmia will act as a “Service Provider” as such term is
              defined in the CCPA, in its performance of its obligations
              pursuant to this DPA or the Agreement. The Customer will act as a
              single point of contact for its Affiliates with respect to CCPA
              compliance, such that if Service Provider gives notice to the
              Customer, such information or notice will be deemed received by
              the Customer's Affiliates. The Parties acknowledge and agree that
              any claims in connection with the CCPA under this DPA will be
              brought by Customer, whether acting for itself or on behalf of an
              Affiliate.
            </li>
            <li>
              Categories of Personal Data contain metadata on employees who use
              the Service in connection with a software development project,
              such as nature and time of modifications as well as identifiers of
              the individual who made the modification. Swarmia may also process
              other categories of Personal Data when such Personal Data is
              included in the Customer Material.
            </li>
          </ol>
        </li>

        <li>
          <h2>Responsibilities of Customer</h2>
          <ol>
            <li>
              The Customer shall comply with the obligations applicable to it as
              a Controller as set out in the Data Protection Regulation and this
              DPA.
            </li>
            <li>
              The Customer is responsible for complying with the CCPA in
              connection with the collection, use and storage of Personal Data
              and will ensure that it obtains all necessary consents, and
              provides all necessary notices, for the lawful Processing of
              Personal Data by Swarmia in accordance with the Agreement.
            </li>
            <li>
              The Customer's documented instructions to Swarmia on the
              processing of Personal Data are given in this DPA. Additional
              instructions require prior written agreement between the Parties.
            </li>
            <li>
              The Customer shall be solely responsible for providing appropriate
              access rights to Swarmia and limiting access to Personal Data as
              strictly necessary for the purpose of the Service.
            </li>
          </ol>
        </li>

        <li>
          <h2>Responsibilities of Swarmia</h2>
          <ol>
            <li>
              Swarmia shall process Personal Data in accordance with this DPA
              and Data Protection Regulation.
            </li>
            <li>
              Swarmia shall ensure that personnel with access to Personal Data
              are subject to confidentiality obligation.
            </li>
            <li>
              Swarmia will process Personal Data only as permitted under this
              DPA, the Agreement or applicable Data Protection Regulation, and
              will not, under any circumstances, collect, combine, share, use,
              retain, access, share, transfer, sell or otherwise process
              Personal Data for any purpose not related to providing the
              Service. Swarmia will refrain from taking any action that would
              cause any transfers of Personal Data to or from Swarmia to qualify
              as “selling personal information” as that term is defined under
              the CCPA.
            </li>
            <li>
              Swarmia shall implement and maintain appropriate technical and
              organizational measures to ensure an appropriate level of security
              to protect Personal Data against unauthorized access and loss,
              destruction, damage, alteration or disclosure, or against other
              unlawful processing. Security measures are described in{' '}
              <a
                className="high-visibility"
                href="https://help.swarmia.com/data-security"
              >
                our support center
              </a>
              .
            </li>
            <li>
              Swarmia shall notify the Customer of Personal Data Breaches
              without undue delay after Swarmia has become aware of the Personal
              Data Breach and take reasonable steps to mitigate any damage
              resulting from such. The notification shall contain at least the
              information required by the Data Protection Regulation. If it is
              not possible to provide the information at the same time, the
              information may be provided in phases. Swarmia shall document
              Personal Data Breaches and provide the documentation to the
              Customer upon request.
            </li>
            <li>
              Swarmia shall, upon the Customer's detailed written request, to a
              reasonable extent assist the Customer, for example by means of
              appropriate technical and organizational measures, in carrying out
              the requests of Data Subjects and supervisory authorities and
              carrying out Data Protection Impact Assessment when required by
              the applicable Data Protection Regulation. The Customer shall
              reimburse Swarmia reasonable costs and expenses incurred from such
              assistance.
            </li>
            <li>
              Swarmia shall to a reasonable extent assist the Customer in
              demonstrating compliance with the Data Protection Regulation, and
              for such purposes, make available to the Customer all information
              available to Swarmia reasonably required and necessary for the
              Customer to demonstrate its compliance.
            </li>
            <li>
              Swarmia may use its Affiliates and third parties as subcontractors
              to provide certain parts of the Service. The Customer hereby
              authorizes Swarmia to use these subcontractors for the processing
              of Personal Data. Swarmia may remove or appoint other suitable and
              reliable subcontractors at its own discretion. Swarmia will notify
              the Customer in writing of a new subcontractor at least fourteen
              (14) days prior to the appointment or replacement of a
              subcontractor. The Customer may, on reasonable grounds related to
              protection of Personal Data, object a subcontractor, in which case
              Swarmia shall use reasonable efforts to find and implement an
              alternative solution which does not include engaging such
              subcontractor. If no alternative solution is reasonably available,
              the Customer may terminate, with immediate effects, the Agreement
              and related Order(s). Swarmia maintains an up to date list of used
              subcontractors with access to Personal Data at{' '}
              <a
                className="high-visibility"
                href="https://www.swarmia.com/subprocessors/"
              >
                https://www.swarmia.com/subprocessors/
              </a>
              , including their processing location and the specific processing
              activities they are engaged for.
            </li>
            <li>
              Swarmia shall ensure that its subcontractors, who have access to
              Personal Data, comply with equivalent obligations as set out in
              this DPA, including security and confidentiality requirements.
              Swarmia remains liable for its subcontractors and the work of its
              subcontractors as for its own.
            </li>
            <li>
              Except for permitted disclosures to subcontractors pursuant to
              similar terms as this DPA, Swarmia shall not disclose, release,
              transfer, make available or otherwise communicate any Personal
              Data to another business or third party without the prior written
              consent of the Customer. Notwithstanding the foregoing, nothing in
              this Agreement shall restrict Swarmia's ability to disclose
              Personal Data to comply with applicable laws or as otherwise
              permitted by the applicable Data Protection Regulation.
            </li>
            <li>
              The Service is hosted within the European Economic Area (”
              <strong>EEA</strong>“). The Customer acknowledges, however, that
              some of the subcontractors are located in or have access to
              Personal Data outside of EEA. To the extent Personal Data is
              processed outside of EEA by subcontractors, the Customer hereby
              approves the processing of Personal Data outside of EEA. Whenever
              Personal Data is transferred to, or accessed from, locations
              outside of EEA, Swarmia is committed to ensuring that such
              transfers are protected by appropriate safeguards in accordance
              with Chapter V of the GDPR.
            </li>
          </ol>
        </li>

        <li>
          <h2>Auditing</h2>
          <ol>
            <li>
              At the Customer's written request and the Customer's sole cost and
              expense, the Customer, or a third party appointed by the Customer,
              is entitled, once every twelve (12) months, to audit Swarmia's
              compliance with this DPA. The audit report and related information
              shall at all times be deemed as Swarmia's confidential
              information. The Customer shall notify Swarmia in writing at least
              thirty (30) days prior to conducting the audit, unless otherwise
              required by applicable law or authority decision.
            </li>
          </ol>
        </li>

        <li>
          <h2>Term and Termination</h2>
          <ol>
            <li>
              This DPA shall continue in force until the termination of the
              Agreement or as long as Swarmia processes Personal Data on behalf
              of the Customer.
            </li>
            <li>
              Upon termination or expiry of the Agreement, or upon the
              Customer's written request, Swarmia shall either destroy or
              return, either to the Customer or to a third party designated by
              the Customer in writing, the Personal Data processed, unless
              otherwise required by Data Protection Regulation or other
              applicable legislation.
            </li>
          </ol>
        </li>

        <li>
          <h2>Changes</h2>
          <ol>
            <li>
              Any changes to this DPA shall be made in writing and signed by
              both Parties in order to be valid and binding.
            </li>
          </ol>
        </li>
      </ol>
    </Block>
  </Page>
)
